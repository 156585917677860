/* eslint-disable @typescript-eslint/no-var-requires */
import auMenu from './AU.json';
import nzMenu from './NZ.json';
import usMenu from './US.json';
import sgMenu from './SG.json';
import caMenu from './CA.json';
import { MenuItem } from './types';

let menu = auMenu;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-au') menu = auMenu;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-nz') menu = nzMenu;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-dev') menu = auMenu;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-us') menu = usMenu;

if (process.env.NEXT_PUBLIC_STORE === 'reebelo-ca') menu = caMenu;

if (process.env.NEXT_PUBLIC_STORE === 'quista') menu = sgMenu;

export default menu as MenuItem[];
