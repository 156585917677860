const ShowMore = ({
  show,
  onClick,
}: {
  show: boolean;
  onClick: () => void;
}) => {
  if (!show) return null;

  return (
    <div className="flex flex-1 items-center gap-4 px-4 py-6 sm:px-6">
      <div className="h-[1px] flex-1 bg-gray-500" />
      <button
        onClick={onClick}
        className="rounded-full border border-gray-700 px-5 py-2 font-bold"
      >
        Show More
      </button>
      <div className="h-[1px] flex-1 bg-gray-500" />
    </div>
  );
};

export default ShowMore;
