import cn from 'classnames';

const Badge = ({ label, className }: { label: string; className?: string }) => (
  <span
    className={cn(
      'rounded-md bg-teal-500 px-1.5 py-0.5 text-[10px] font-light',
      className,
    )}
  >
    {label}
  </span>
);

export default Badge;
